exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-install-feedback-tsx": () => import("./../../../src/pages/install-feedback.tsx" /* webpackChunkName: "component---src-pages-install-feedback-tsx" */),
  "component---src-pages-list-tsx": () => import("./../../../src/pages/list.tsx" /* webpackChunkName: "component---src-pages-list-tsx" */),
  "component---src-pages-listing-report-tsx": () => import("./../../../src/pages/listingReport.tsx" /* webpackChunkName: "component---src-pages-listing-report-tsx" */),
  "component---src-pages-uninstall-feedback-tsx": () => import("./../../../src/pages/uninstall-feedback.tsx" /* webpackChunkName: "component---src-pages-uninstall-feedback-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

